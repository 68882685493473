<template>
  <!-- gray -->
  <div class="meCoupon_li">
    <div class="important flex-container">
      <div class="coupon_price flex-container">
        <p class="price">
          <i>￥</i>
          <b>{{ coupon.voucher_price }}</b>
        </p>
        <p class="tips">优惠券</p>

        <img src="@image/me/coupon_jtop2.png" class="top" />
        <img
          src="@image/me/coupon_jbottom2.png"
          class="bottom"
          v-if="!infoShow"
        />
      </div>
      <div class="coupon_data flex-container">
        <p class="title">{{ coupon.voucher_title }}</p>
        <b class="time">{{ coupon.vouchertemplate_enddate | time }}到期</b>
        <!-- <b class="time lastTime">还剩1天到期</b> -->
        <span
          class="rules"
          @click="infoShow = !infoShow"
          :class="infoShow ? 'rulesShow' : 'rulesHide'"
          >使用规则</span
        >
      </div>
      <template>
        <div class="state" v-if="coupon.voucher_state > 1">
          <img
            src="@image/me/coupon_used.png"
            v-if="coupon.voucher_state == 2"
          />
          <img src="@image/me/coupon_nodate.png" v-else />
        </div>
        <div class="go_btn" v-else>
          <p
            @click="gotoLink(coupon.jump_link)"
            v-if="coupon.voucher_state == 1"
          >
            去使用
          </p>
          <p v-else>{{ coupon.voucher_state_text }}</p>
        </div>
      </template>
    </div>

    <div class="info" v-if="infoShow">
      <p>{{ coupon.vouchertemplate_desc }}</p>
      <p class="only_storeName">
        仅限于{{ coupon.vouchertemplate_storename }}店铺使用
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    coupon: {
      type: Object,
    },
    state: {
      default: false,
    },
  },
  methods: {
    gotoLink(link) {
      if (link) {
        window.open(link);
      } else {
        this.$router.push({ name: "HomeIndex" });
      }
    },
  },
  created() {
    //do something after creating vue instance
  },
  data: () => ({
    infoShow: false,
  }),
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.only_storeName {
  color: #ff4747;
  margin-top: rem(10);
}
.meCoupon_li {
  width: rem(710);
  margin: 0 auto rem(20);
  background: #fff;
  border-radius: rem(10);
  .info {
    // align-items: center;

    // min-height: rem(68);
    font-size: rem(20);
    color: #808080;
    // line-height: rem(68);
    padding: rem(20);
  }
  .important {
    .coupon_price {
      .top,
      .bottom {
        position: absolute;
        width: rem(16);
        height: rem(8);
        right: rem(-8);
      }
      .top {
        top: 0;
      }
      .bottom {
        bottom: 0;
      }

      width: rem(220);
      height: rem(200);
      background: url("~@image/me/coupon_quan.png") no-repeat;
      background-size: rem(220) rem(200);
      justify-content: center;
      flex-direction: column;
      position: relative;
      .price {
        text-align: center;
        color: #ff4747;

        i {
          font-size: rem(30);
          font-weight: bold;
        }
        b {
          font-size: rem(64);
          font-weight: bold;
        }
      }
      .tips {
        text-align: center;
        font-size: rem(22);
        font-weight: bold;
        color: #ff4747;
      }
    }

    .coupon_data {
      width: rem(320);
      justify-content: center;
      flex-direction: column;
      padding-left: rem(24);
      box-sizing: border-box;
      .title {
        font-size: rem(26);
        font-weight: bold;
        color: #333333;
        @include ell2;
      }
      .time {
        font-size: rem(22);
        color: #b2b2b2;
        display: block;
      }
      .lastTime {
        color: #ff4747;
      }
      .rules {
        margin-top: rem(16);
        display: block;
        width: rem(106);
        font-size: rem(20);
        color: #b2b2b2;
      }
      .rulesShow {
        background: url("~@image/me/coupon_show.png") no-repeat center right;
        background-size: rem(20) rem(20);
      }
      .rulesHide {
        background: url("~@image/me/coupon_hide.png") no-repeat center right;
        background-size: rem(20) rem(20);
      }
    }
  }

  .go_btn {
    width: rem(120);
    height: rem(52);
    background: #ff4747;
    box-shadow: 0px rem(4) rem(8) 0px rgba(255, 71, 71, 0.2);
    border-radius: rem(26);
    font-size: rem(26);
    font-weight: bold;
    text-align: center;
    line-height: rem(52);
    color: #ffffff;
    margin: rem(74) auto;
  }
}

.state {
  margin: rem(40) auto 0;
  img {
    width: rem(120);
    height: rem(120);
    display: block;
  }
}

.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);

  filter: grayscale(100%);

  filter: gray;
}
</style>
